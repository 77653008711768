/* eslint-disable no-shadow */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, { useEffect, useState, createContext } from 'react';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import api from 'services/apiClient';
import encodeRFC5987ValueChars from 'utils/encodeRFC5987ValueChars';
import verifyUserPermission from 'utils/verifyUserPermission';

import ZeroItemsInclusiveDTO from 'dtos/IZeroItemsInclusiveDTO';
import IZeroItemsInclusiveParamsDTO from 'dtos/IWarehouseParamsDTO';
import ItemPricesParams from 'dtos/IItemPricesParamsDTO';

import Header from 'components/Header';
import { uniqueId } from 'lodash';
import { FormReposicao } from 'components/FormReposicao';
import ItemPrices from 'components/ItemPrices';
import { HeaderContent, WarehouseInfo, Warehouses } from './styles';

export const pedidoContext = createContext({} as any);

const Item: React.FC = () => {
  const { params } = useRouteMatch<IZeroItemsInclusiveParamsDTO>();
  const [item, setItem] = useState<ZeroItemsInclusiveDTO | null>(null);
  const [itemPrices, setItemPrices] = useState<ItemPricesParams | null>(null);
  const [itensArray, setItensArray] = useState<ZeroItemsInclusiveDTO[] | null>(
    null,
  );
  const [repo, setRepo] = useState(0);

  const history = useHistory<History>();
  const findPermission = verifyUserPermission('list_shelves');
  const findReposition = verifyUserPermission('button_replacement');

  useEffect(() => {
    api
      .get(`service/${encodeRFC5987ValueChars(params.itemCode)}`)
      .then(response => {
        setItem(response.data[0]);
        setItensArray(response.data);
      });
    api
      .get(`monday/item-prices/${encodeRFC5987ValueChars(params.itemCode)}`)
      .then(response => {
        setItemPrices(response.data);
      });
  }, [params.itemCode]);

  return (
    <>
      <Header />
      <HeaderContent>
        <button type="button" onClick={history.goBack}>
          <FiChevronLeft size={16} />
          Voltar
        </button>
      </HeaderContent>

      {item && (
        <>
          <WarehouseInfo>
            <header>
              <img
                src={`${process.env.REACT_APP_IMG_URL}/${item.partNumber}.png`}
                alt=""
                onError={event => {
                  event.currentTarget.src = `${process.env.REACT_APP_IMG_URL}/image_not_available.png`;
                }}
              />
              <div>
                <strong>{item.partNumber}</strong>
                <p>{item.description}</p>
              </div>
            </header>
            <ul>
              {item.ncmCode && (
                <li>
                  <strong>{item.ncmCode}</strong>
                  <span>NCM</span>
                </li>
              )}
              {item.ipi && (
                <li>
                  <strong>{`${item.ipi}%`}</strong>
                  <span>IPI</span>
                </li>
              )}
              <li>
                <pedidoContext.Provider value={{ item, repo, setRepo }}>
                  {findReposition ? <FormReposicao item={item} /> : <></>}
                </pedidoContext.Provider>
              </li>
            </ul>
          </WarehouseInfo>
          <ItemPrices
            price_usd={itemPrices?.price_usd}
            price_brl={itemPrices?.price_brl}
            ship_chandler_price={itemPrices?.ship_chandler_price}
            item_observation={itemPrices?.item_observation}
          />
        </>
      )}

      {findPermission
        ? itensArray && (
            <Warehouses>
              {itensArray.map(item =>
                item.quantity === 0 ? (
                  <a key={uniqueId('autoId')}>
                    <div>
                      <strong>{item.location}</strong>
                      <p />
                    </div>
                    <div className="quantity">
                      <strong>{Math.round(item.quantity)}</strong>
                      <p>Qtd</p>
                    </div>
                  </a>
                ) : (
                  <Link
                    onError={event => event.stopPropagation()}
                    key={uniqueId('autoId')}
                    to={`/shelfs/${item.itemCode}/${item.location}`}
                  >
                    <div>
                      <strong>{item.location}</strong>
                      <p />
                    </div>
                    <div className="quantity">
                      <strong>{Math.round(item.quantity)}</strong>
                      <p>Qtd</p>
                    </div>
                  </Link>
                ),
              )}
            </Warehouses>
          )
        : itensArray && (
            <Warehouses>
              {itensArray.map(item => (
                <a key={uniqueId('autoId')}>
                  <div>
                    <strong>{item.location}</strong>
                    <p />
                  </div>
                  <div className="quantity">
                    <strong>{Math.round(item.quantity)}</strong>
                    <p>Qtd</p>
                  </div>
                </a>
              ))}
            </Warehouses>
          )}
    </>
  );
};

export default Item;
