import styled, { keyframes } from 'styled-components';
import { shade, lighten } from 'polished';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 920px;
`;

const appearFromLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(-50px)
}
to {
  opacity: 1;
  transform: translateX(0)
}
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 1.5rem;
    }

    a {
      color: #2e2e2e;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      align-items: center;

      &:hover {
        color: ${lighten(0.3, '#2e2e2e')};
      }

      svg {
        margin-right: 1rem;
      }
    }
  }
  > a {
    color: #3a3a3a;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 1rem;
    }

    &:hover {
      color: ${shade(0.2, '#3a3a3a')};
    }
  }
`;
