import styled, { css } from 'styled-components';

import Tooltip from '../Tolltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #fff;
  border-radius: ${props => props.theme['--border-radius']};
  padding: 16px;
  width: 100%;

  border: 2px solid #f0f0f5;

  color: #666360;

  display: flex;
  align-items: center;

  & + div {
    /* Cada div depois do primeiro tera 8px de margin-top  */
    margin-top: 0.5rem;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: #595859;
      border-color: #595859;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #595859;
    `}

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #3a3a3a;

    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  /* Tooltip estilizado */
  height: 1.25rem; /* Altura de 20 para ficar do tamanho do icone de erro a não distorcer o input */
  margin-left: 1rem; /* Margem esquerda com 16px para o texto do input não passar por cima do icone de erro. */

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: ${props => props.theme.white};

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
