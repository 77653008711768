/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React, { useRef, useEffect } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useReducer } from 'react';
import axios, { CancelToken } from 'axios';
import encodeRFC5987ValueChars from '../../utils/encodeRFC5987ValueChars';
import NoItensFound from '../NoItensFound';
import { WarehouseItemCards as ZeroItemsInclusiveCards } from './styles';
import ZeroItemsInclusiveDTO from '../../dtos/IZeroItemsInclusiveDTO';
import image_not_available from '../../assets/image_not_available.png';

interface ZeroItemsInclusiveProps {
  query: string;
}

type StateType = {
  isLoading: boolean;
  hasError: boolean;
  hits?: ZeroItemsInclusiveDTO[];
  resultState: 'initialState' | 'success' | 'noItensFound';
};

type ActionType =
  | { type: 'FETCH_START' }
  | { type: 'FETCH_SUCCESS'; payload: ZeroItemsInclusiveDTO[] }
  | { type: 'FETCH_FAILURE' };

function fetchReduce(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'FETCH_START':
      return {
        ...state,
        isLoading: true,
        hasError: false,
        resultState: 'initialState',
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasError: false,
        hits: action.payload,
        resultState: 'success',
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        hasError: true,
        hits: [],
        resultState: 'noItensFound',
      };
    default:
      throw new Error();
  }
}

const ZeroItemsInclusiveList: React.FC<ZeroItemsInclusiveProps> = React.memo(
  ({ query }: ZeroItemsInclusiveProps) => {
    const [{ hits, isLoading, resultState }, dispatch] = useReducer(
      fetchReduce,
      {
        hits: [],
        isLoading: true,
        hasError: false,
        resultState: 'initialState',
      },
    );

    async function fetchHits(
      query: string,
      dispatch: React.Dispatch<ActionType>,
      cancelToken: CancelToken,
    ): Promise<void> {
      dispatch({ type: 'FETCH_START' });
      try {
        const result = await axios(
          `${process.env.REACT_APP_API_URL}/service/find?item=${query}`,
          {
            cancelToken,
          },
        );
        const arrayLength = result.data.length;

        if (arrayLength === 0 && query !== 'nonExistentItem') {
          dispatch({ type: 'FETCH_FAILURE' });
          return;
        }
        if (query === ' ') {
          return;
        }
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
      } catch (err) {
        axios.isCancel(err) || dispatch({ type: 'FETCH_FAILURE' });
      }
    }

    const debouncedFetchHits = useRef(
      _.debounce(
        (query: string, cancelToken: CancelToken) =>
          fetchHits(query, dispatch, cancelToken),
        500,
      ),
    ).current;

    useEffect(() => {
      const { cancel, token } = axios.CancelToken.source();
      debouncedFetchHits(query, token);
      return () => cancel('No longer latest query');
    }, [debouncedFetchHits, query]);

    return (
      <>
        {resultState === 'noItensFound' && <NoItensFound />}

        <ZeroItemsInclusiveCards>
          {hits?.map(warehouseItem => (
            <Link
              key={warehouseItem.itemCode + Date()}
              to={`/service/${encodeRFC5987ValueChars(warehouseItem.itemCode)}`}
            >
              {isLoading ? (
                <Skeleton variant="circle">
                  <img alt="" />
                </Skeleton>
              ) : (
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/${warehouseItem.partNumber}.png`}
                  alt=""
                  onError={e => {
                    e.currentTarget.src = image_not_available;
                  }}
                />
              )}

              <div>
                {isLoading ? (
                  <Skeleton variant="text" width="100%" height="auto">
                    <strong>.</strong>
                  </Skeleton>
                ) : (
                  <strong>
                    {warehouseItem.partNumber === ''
                      ? 'Falta informação no ERP'
                      : warehouseItem.partNumber}
                  </strong>
                )}
                {isLoading ? (
                  <Skeleton variant="text" width="100%" height="auto">
                    <p>.</p>
                  </Skeleton>
                ) : (
                  <p>{warehouseItem.description}</p>
                )}
              </div>

              <div className="quantity">
                {isLoading ? (
                  <Skeleton
                    variant="text"
                    height="auto"
                    width="auto"
                    className="quantity"
                  >
                    <strong>.</strong>
                  </Skeleton>
                ) : (
                  <strong>{Math.round(warehouseItem.quantity)}</strong>
                )}

                {isLoading ? (
                  <Skeleton width="100%" height="auto" />
                ) : (
                  <p>Qtd</p>
                )}
              </div>
              <FiChevronRight size={20} />
            </Link>
          ))}
        </ZeroItemsInclusiveCards>
      </>
    );
  },
);

export default ZeroItemsInclusiveList;
