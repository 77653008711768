/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, { useEffect, useState, createContext } from 'react';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import api from 'services/apiClient';
import encodeRFC5987ValueChars from 'utils/encodeRFC5987ValueChars';
import verifyUserPermission from 'utils/verifyUserPermission';

import WareHouseItem from 'dtos/IWareHouseItemDTO';
import WarehouseParams from 'dtos/IWarehouseParamsDTO';
import ItemPricesParams from 'dtos/IItemPricesParamsDTO';

import Header from 'components/Header';
import { uniqueId } from 'lodash';
import { FormReposicao } from 'components/FormReposicao';
import ItemPrices from 'components/ItemPrices';
import { HeaderContent, WarehouseInfo, Warehouses } from './styles';
import image_not_available from '../../assets/image_not_available.png';

export const pedidoContext = createContext({} as any);

const Item: React.FC = () => {
  const { params } = useRouteMatch<WarehouseParams>();
  const [item, setItem] = useState<WareHouseItem | null>(null);
  const [itemPrices, setItemPrices] = useState<ItemPricesParams | null>(null);
  const [itensArray, setItensArray] = useState<WareHouseItem[] | null>(null);
  const [repo, setRepo] = useState(0);

  const history = useHistory<History>();
  const findPermission = verifyUserPermission('list_shelves');
  const findReposition = verifyUserPermission('button_replacement');

  useEffect(() => {
    api
      .get(`warehouse/${encodeRFC5987ValueChars(params.itemCode)}`)
      .then(response => {
        setItem(response.data[0]);
        setItensArray(response.data);
      });
    api
      .get(`monday/item-prices/${encodeRFC5987ValueChars(params.itemCode)}`)
      .then(response => {
        setItemPrices(response.data);
      });
  }, [params.itemCode]);

  return (
    <>
      <Header />
      <HeaderContent>
        <button type="button" onClick={history.goBack}>
          <FiChevronLeft size={16} />
          Voltar
        </button>
      </HeaderContent>

      {item && (
        <>
          <WarehouseInfo>
            <header>
              <img
                src={`${process.env.REACT_APP_IMG_URL}/${item.partNumber}.png`}
                alt=""
                onError={event => {
                  event.currentTarget.src = image_not_available;
                }}
              />
              <div>
                <strong>{item.partNumber}</strong>
                <p>{item.description}</p>
              </div>
            </header>
            <ul>
              {item.ncmCode && (
                <li>
                  <strong>{item.ncmCode}</strong>
                  <span>NCM</span>
                </li>
              )}
              {item.ipi && (
                <li>
                  <strong>{`${item.ipi}%`}</strong>
                  <span>IPI</span>
                </li>
              )}
              <li>
                <pedidoContext.Provider value={{ item, repo, setRepo }}>
                  {findReposition ? <FormReposicao item={item} /> : <></>}
                </pedidoContext.Provider>
              </li>
            </ul>
          </WarehouseInfo>
          <ItemPrices
            price_usd={itemPrices?.price_usd}
            price_brl={itemPrices?.price_brl}
            ship_chandler_price={itemPrices?.ship_chandler_price}
            item_observation={itemPrices?.item_observation}
          />
        </>
      )}
      {findPermission
        ? itensArray && (
        <Warehouses>
              {itensArray.map(warehouseItem => (
            <Link
                  onError={event => event.stopPropagation}
                  key={uniqueId('autoId')}
                  to={`/shelfs/${warehouseItem.itemCode}/${warehouseItem.location}`}
                >
                  <div>
                <strong>{warehouseItem.location}</strong>
                <p />
              </div>
                  <div className="quantity">
                <strong>{Math.round(warehouseItem.quantity)}</strong>
                <p>Qtd</p>
              </div>
                </Link>
              ))}
            </Warehouses>
          )
        : itensArray && (
        <Warehouses>
              {itensArray.map(warehouseItem => (
            <a key={uniqueId('autoId')}>
                  <div>
                <strong>{warehouseItem.location}</strong>
                <p />
              </div>
                  <div className="quantity">
                <strong>{Math.round(warehouseItem.quantity)}</strong>
                <p>Qtd</p>
              </div>
                </a>
              ))}
            </Warehouses>
          )}
    </>
  );
};

export default Item;
