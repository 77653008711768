import styled from 'styled-components';

export const Container = styled.div`
  padding: 0;
  margin-left: 1.5rem;
  margin-top: 2rem;

  display: flex;
  align-items: center;
  justify-content: left;
`;
