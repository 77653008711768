import styled from 'styled-components';

export const WarehouseItemCards = styled.div`
  margin-top: 2rem;
  max-width: 872px;

  a {
    box-sizing: content-box;
    background: ${props => props.theme.white};
    border-radius: ${props => props.theme['--border-radius']};
    width: 100%;
    padding: 24px;
    display: block;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: transform 0.2s;

    &:hover {
      transform: translateX(2px);
      cursor: unset;
    }

    img {
      margin: 0 0 0 16px;
      width: 80px;
      height: 80px;
    }

    div {
      padding-left: 1rem;
      display: flex;
      flex-direction: column;
      margin: 0 0 0 16px;
      flex: 1;

      min-width: 100px;
      overflow: hidden;

      strong {
        box-sizing: content-box;
        font-size: 1.25rem;
        color: ${props => props.theme['gray-500']};
      }

      p {
        box-sizing: content-box;
        font-size: 18px;
        color: #a8a8b3;
        margin-top: 4px;
      }
    }

    div.quantity {
      display: flex;
      flex-direction: column;
      justify-content: right;
      margin: 0 32px 0 0;
      overflow: hidden;
      text-align: right;
      strong {
        text-align: right;
        font-size: 32px;
        color: ${props => props.theme['gray-500']};
      }
    }
  }
`;
